.table {
  @apply w-full;
}

.table td:first-of-type {
  @apply py-4;
  @apply px-12;
}

.table td:not(:first-of-type) {
  text-align: center;
  width: 7rem;
  height: 2rem;
}

/* .table td:not(:first-of-type):hover {
  @apply bg-gray-50;
} */

/* .table tr:nth-child(even) {
  @apply bg-gray-200;
} */

.table tr {
  @apply cursor-default;
}

.table thead {
  margin-bottom: 25px;
}

.table th:hover {
  background: none;
}

.table tbody tr:hover {
  @apply bg-gray-200;
  transition: all 250ms;
}

.sv_qstn label.sv_q_m_label {
  @apply text-center;
  display: flex !important;
  align-items: center;
  justify-content: center;
  position: relative !important;
  width: 100%;
  height: 100%;
}

/* .table label.sv_q_m_label:hover {
  @apply bg-gray-800;
  transition: all 250ms;
} */

.button {
  @apply bg-gray-100;
  @apply rounded px-4 py-2;
  @apply text-black;
  @apply font-semibold;
  @apply hover:bg-gray-200;
  float: right;
}

.table thead {
  margin-bottom: 2rem;
}

.table input[type="radio"] {
  border: 0px;
  width: 100%;
  height: 18px;
}
